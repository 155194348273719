import { CommonModule } from '@angular/common';
import { Component, effect, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { FFFeatureFlagsService } from '@bannerflow/feature-flags';
import { Environments, UIModule, UINewThemeService, UIThemeService } from '@bannerflow/ui';
import { FeatureFlags } from 'src/domain/feature-flags.type';
import { FontService } from 'src/services';
import { GoogleTagManagerService } from 'src/services/google-tag-manager.service';
import { TrackerService } from 'src/services/tracker.service';

@Component({
    imports: [CommonModule, UIModule, RouterOutlet],
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    private fontService = inject(FontService);
    private ffFeatureFlagService = inject(FFFeatureFlagsService);
    private googleTagManagerService = inject(GoogleTagManagerService); // Make sure GTM is initialized
    private trackerService = inject(TrackerService);
    private uiNewThemeService = inject(UINewThemeService);

    private isNuiEnabled = toSignal(this.ffFeatureFlagService.isEnabled$(FeatureFlags.NUI), {
        initialValue: false
    });

    get loading(): boolean {
        return this.fontService.isLoading;
    }

    constructor() {
        inject(UIThemeService); // To make sure that the themes are read from the URL, we need to import themeService somewhere. No need for any actions though.
        this.trackerService.initNewRelic();

        this.googleTagManagerService.initializeGtm();

        this.uiNewThemeService.setEnvironment(Environments.Platform, 'global');

        effect(() => {
            const isNuiEnabled = this.isNuiEnabled();
            if (isNuiEnabled) {
                this.uiNewThemeService.enableNewTheme();
                document.documentElement.setAttribute('data-variant', 'md');
            } else {
                this.uiNewThemeService.disableNewTheme();
            }
        });
    }
}
