import { Component, Input, EventEmitter, Output, inject, computed } from '@angular/core';
import { FontFamily } from '../../models/fontFamily.model';
import { UIModule, UIButtonGroupOption, UINewThemeService } from '@bannerflow/ui';
import moment from 'moment';

@Component({
    imports: [UIModule],
    selector: 'font-style-header',
    templateUrl: './font-style-header.component.html',
    styleUrls: ['./font-style-header.component.scss']
})
export class FontStyleHeaderComponent {
    uiNewThemeService = inject(UINewThemeService);

    @Input() fontFamily: FontFamily;

    @Output('selectionChange')
    selectionChange: EventEmitter<string> = new EventEmitter<string>();

    isNewUI = computed(() => this.uiNewThemeService.isNewUI());

    parseDate(date: Date): string {
        return moment(date).format('YYYY-MM-DD');
    }

    selectedOption = 'compact';

    buttonOptions: UIButtonGroupOption[] = [
        {
            id: 'compact-list',
            icon: 'compact-list',
            value: 'compact'
        },
        {
            id: 'expanded-list',
            icon: 'expanded-list',
            value: 'expanded'
        },
        { id: 'dots', icon: 'dots', value: 'glyphs' }
    ];

    tabOptions = [
        {
            icon: 'menu',
            value: 'compact'
        },
        {
            icon: 'view_agenda',
            value: 'expanded'
        },
        {
            icon: 'font_download',
            value: 'glyphs'
        }
    ];

    updateSelectedOption(event: string): void {
        this.selectedOption = event;
        this.selectionChange.emit(this.selectedOption);
    }
    selectedTabChange(isSelected: boolean, tabName: string): void {
        if (isSelected === false || tabName === this.selectedOption) {
            return;
        }
        this.updateSelectedOption(tabName);
    }
}
