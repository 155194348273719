import { Component, computed, inject, signal } from '@angular/core';
import { FontUploadService } from '../../../services/font-upload.service';
import { UIModule, UINewThemeService, UINotificationService } from '@bannerflow/ui';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'upload-from-device',
    templateUrl: './upload-from-device.component.html',
    styleUrls: ['./upload-from-device.component.scss', './upload-from.device.new.component.scss'],
    imports: [CommonModule, UIModule]
})
export class UploadFromDeviceComponent {
    private fontUploadService = inject(FontUploadService);
    private uiNotificationService = inject(UINotificationService);
    private uiNewThemeService = inject(UINewThemeService);

    isError = signal(false);
    isNewUI = computed(() => this.uiNewThemeService.isNewUI());

    async onFilesUploaded(files: File[]): Promise<void> {
        this.isError.set(false);

        const uploadSuccessful = await this.fontUploadService.uploadFiles(files);

        if (uploadSuccessful) {
            this.displaySuccess();
        } else {
            this.isError.set(true);
            this.displayError();
        }
    }

    private displaySuccess(): void {
        this.uiNotificationService.open('Font uploaded successfully!', {
            type: 'success',
            placement: 'bottom',
            autoCloseDelay: 3000
        });
    }

    private displayError(
        errorText = 'Something went wrong when uploading the file. Please try again.'
    ): void {
        this.uiNotificationService.open(errorText, {
            type: 'error',
            placement: 'bottom',
            autoCloseDelay: 3000
        });
    }
}
