import { computed, inject, Injectable, OnDestroy, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { Subject } from 'rxjs';
import { UserContext } from 'src/domain/context.type';
import { EnvironmentService } from './environment.service';

const ADMIN_ROLE = '2';
const EMPLOYEE_ROLE = '3';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
    private auth0Service = inject(Auth0Service);
    private environmentService = inject(EnvironmentService);

    isAdmin = computed(() => {
        const context = this.context();
        if (!context) {
            return false;
        }
        return context.role === ADMIN_ROLE || context.employee === EMPLOYEE_ROLE;
    });

    isAuthenticated = signal<boolean>(false);

    private _context$ = new Subject<UserContext>();
    context$ = this._context$.asObservable();
    context = toSignal(this._context$.asObservable());

    constructor() {
        const inIframe = window.self !== window.top;
        if (inIframe) {
            window.addEventListener('message', this.handleMessage);
            window.top?.postMessage('fm-ready', this.environmentService.getStudioDomain());
            return;
        }

        this.auth0Service.user$.pipe(takeUntilDestroyed()).subscribe(user => {
            if (user) {
                this._context$.next({
                    token: this.context()?.token ?? '',
                    role: user.role,
                    employee: user.employee
                });
            }
        });

        this.auth0Service.isAuthenticated$.subscribe(isAuthenticated => {
            this.isAuthenticated.set(isAuthenticated);
        });
    }

    ngOnDestroy(): void {
        window.removeEventListener('message', this.handleMessage);
    }

    login(): void {
        this.auth0Service.loginWithPopup();
    }

    logout(): void {
        this.auth0Service.logout();
    }

    private handleMessage = (event: Event): void => {
        if (event.type !== 'message') {
            return;
        }
        const userContext = (event as MessageEvent).data as { token: string };
        if (!userContext || !('token' in userContext)) {
            return;
        }
        const { employee, role } = this.parseJwt(userContext.token);

        this._context$.next({
            token: userContext.token,
            employee,
            role
        });
        this.isAuthenticated.set(!!userContext.token);
    };

    private parseJwt(token: string): { employee: string; role: string } {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
                })
                .join('')
        );

        const payload = JSON.parse(jsonPayload);
        return {
            employee: payload['employee'] ?? '',
            role: payload['role'] ?? ''
        };
    }
}
