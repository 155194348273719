import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { authHttpInterceptorFn } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';
import { AuthService } from '../services/auth.service';

export const authInteceptorFn: HttpInterceptorFn = (req, next) => {
    const authService = inject(AuthService);

    const inIframe = window.self !== window.top;
    if (!inIframe) {
        return authHttpInterceptorFn(req, next);
    }
    const context = authService.context();
    if (!context) {
        return next(req);
    }
    if (req.url.includes(environment.origins.fontManagerApi)) {
        req = req.clone({
            setHeaders: {
                Authorization: `Bearer ${context.token}`
            }
        });
    }
    return next(req);
};
