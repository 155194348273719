@let fontStyleValue = fontStyle;
@let customTextValue = customText();
@let displayTypeValue = displayType();

<div class="wrapper">
    @if (isNewUI()) {
        <ui-checkbox
            #checkbox
            class="item-name-checkbox"
            [selected]="isStyleSelected"
            (click)="selectionChanged()" />

        <div class="font-style-container">
            <div class="item-name-container">
                <div class="item-name">
                    @if (fontStyle.source === 'upload') {
                        <!-- <ui-svg-icon
                        class="font-icon"
                        icon="font-file" /> -->

                        <!-- there is no new font-file so this is just to show something -->
                        <ui-svg-icon
                            class="font-icon"
                            icon="folder" />
                    }

                    @if (fontStyle.source === 'googleFonts') {
                        <img
                            src="assets/icons/google-fonts.svg"
                            alt="Google Fonts" />
                    }

                    @if (!showRenameInput) {
                        <span
                            class="item-name-text"
                            (click)="checkbox.setSelected(!isStyleSelected); selectionChanged()">
                            {{ fontStyleValue.name }}
                        </span>
                    }
                    @if (showRenameInput) {
                        <span class="item-name-text">
                            <ui-input
                                #fontStyleNameInput
                                [(value)]="newName"
                                variant="xs"
                                autocomplete="off"
                                placeholder="Font Style Name" />
                        </span>
                    }
                </div>
            </div>

            <font-preview
                [fontFamily]="fontFamily()"
                [fontStyle]="fontStyle"
                [customText]="customTextValue"
                [displayType]="displayTypeValue"></font-preview>
        </div>
    } @else {
        <div class="item-name-container">
            <div class="item-name">
                <ui-checkbox
                    #checkbox
                    class="item-name-checkbox"
                    [selected]="isStyleSelected"
                    (click)="selectionChanged()" />
            </div>
            <div class="item-name">
                @if (fontStyle.source === 'upload') {
                    <ui-svg-icon
                        class="font-icon"
                        icon="font-file" />
                }

                @if (fontStyle.source === 'googleFonts') {
                    <img
                        src="assets/icons/google-fonts.svg"
                        alt="Google Fonts" />
                }

                @if (!showRenameInput) {
                    <span
                        class="item-name-text"
                        (click)="checkbox.setSelected(!isStyleSelected); selectionChanged()">
                        {{ fontStyleValue.name }}
                    </span>
                }
                @if (showRenameInput) {
                    <span class="item-name-text">
                        <ui-input
                            #fontStyleNameInput
                            [(value)]="newName"
                            autocomplete="off"
                            placeholder="Font Style Name" />
                    </span>
                }
            </div>
        </div>

        <font-preview
            [fontFamily]="fontFamily()"
            [fontStyle]="fontStyle"
            [customText]="customTextValue"
            [displayType]="displayTypeValue"></font-preview>
    }
</div>

@if (isAllowedToModifyFont(fontFamily())) {
    @let textToShow = fontStyles.length > 1 ? fontStyles.length + ' styles' : 'style';
    <div class="item-action-buttons">
        <button
            class="item-action-button"
            [uiDropdownTarget]="menu"
            (click)="toggleKebabMenu()">
            <ui-icon icon="more-standing" />
        </button>

        <ui-dropdown
            #menu
            [positions]="leftAlignedDropDownPositions">
            <ui-dropdown-item
                svgIcon="edit"
                (click)="openRenameInput()">
                Rename
            </ui-dropdown-item>
            <ui-dropdown-item
                svgIcon="folder-move"
                [uiDropdownTarget]="moveMenu.uiDropdown">
                Move {{ textToShow }} to family
            </ui-dropdown-item>
            <ui-dropdown-item
                svgIcon="download"
                (click)="downloadStyle()">
                Download {{ textToShow }}
            </ui-dropdown-item>
            <ui-dropdown-divider />
            <ui-dropdown-item
                svgIcon="delete"
                class="destructive"
                (click)="confirmDeletion()">
                Delete {{ textToShow }}
            </ui-dropdown-item>
            <move-merge-menu
                #moveMenu
                [fontFamily]="fontFamily()"
                mergeOrMove="move"
                (menuChoiceClicked)="confirmMoveFontStyle($event)" />
        </ui-dropdown>
    </div>
} @else {
    <div class="item-action-buttons">
        <button
            class="item-action-button download"
            (click)="downloadStyle()">
            <ui-svg-icon icon="download" />
        </button>
    </div>
}
