import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { AuthGuard as Auth0Guard, AuthService as Auth0Service } from '@auth0/auth0-angular';
import { filter, map, switchMap, take } from 'rxjs';
import { AuthService } from 'src/services/auth.service';

export const canActivateGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const auth0Service = inject(Auth0Service);
    const auth0Guard = inject(Auth0Guard);

    const inIframe = window.self !== window.top;
    if (!inIframe) {
        return auth0Service.isAuthenticated$.pipe(
            filter(isAuthenticated => isAuthenticated !== null),
            take(1),
            switchMap(() => auth0Guard.canActivate(route, state))
        );
    }

    const authService = inject(AuthService);
    return authService.context$.pipe(map(context => !!context.token));
};
