import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { IAppEnvironment } from 'src/domain/environment.type';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
    private router = inject(Router);

    private environment: IAppEnvironment = environment;

    private currentRoute$ = this.router.events.pipe(
        takeUntilDestroyed(),
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        map(event => {
            if (event.url.includes('in-frame')) {
                return 'iframe';
            }
            if (event.url.includes('in-dialog')) {
                return 'page';
            }
            return 'none';
        })
    );
    currentRoute = toSignal(this.currentRoute$, { initialValue: 'none' });

    getOrigin(origin: keyof IAppEnvironment['origins']): string {
        return this.environment.origins[origin];
    }

    getNewRelicConfig(): IAppEnvironment['newRelic'] {
        return this.environment.newRelic;
    }

    getGoogleTagManagerId(): IAppEnvironment['gtmId'] {
        return this.environment.gtmId;
    }

    getClientRoot(): IAppEnvironment['clientRoot'] {
        return this.environment.clientRoot;
    }

    isProduction(): boolean {
        return this.environment.stage === 'production';
    }

    getStudioDomain(): string {
        return this.environment.studioDomain;
    }
}
