import { IAppEnvironment } from 'src/domain/environment.type';

export const environment: IAppEnvironment = {
    stage: 'production',
    production: true,
    clientRoot: 'https://fontmanager.bannerflow.com/',
    gtmId: 'GTM-W5CTW5Z',
    newRelic: {
        enabled: true,
        accountId: '4122654',
        trustKey: '4122654',
        agentId: '538562972',
        licenseKey: 'NRJS-bd28b6acdc31b77b97c',
        applicationId: '538562972'
    },
    auth0: {
        clientId: '308P4fvBQ7kM3dECXW4U2EhT1BQ63AfQ'
    },
    origins: {
        auth0Login: 'https://login.bannerflow.com',
        fontManagerApi: 'https://api.bannerflow.com/font-manager/api'
    },
    studioDomain: 'https://studio.bannerflow.com',
    featureFlags: {
        enabled: true,
        clientKey: '*:production.f01aefe11ea73c1e131c3d4ca573ecb2ad9226bbaf9199362dd61ae2',
        appName: 'FontManager',
        url: 'https://bf-feature-flags.azurewebsites.net/api/frontend'
    }
};
