<div class="component-wrapper ui-scrollbar">
    <div class="component-container">
        <div class="fonts-column">
            <div class="search-container">
                <form [formGroup]="searchForm">
                    <ui-input
                        role="search"
                        class="search-input"
                        placeholder="Search..."
                        icon="search"
                        formControlName="searchTerm" />
                </form>
            </div>
            @if (isLoading) {
                <div class="loader-wrapper">
                    <div class="loader-container">
                        <ui-loader
                            [inline]="true"
                            class="loader" />
                    </div>
                </div>
            } @else {
                @if (getFonts().length === 0) {
                    <div
                        class="no-results"
                        role="alert">
                        No fonts found.
                    </div>
                } @else {
                    <div
                        #fontsList
                        class="fonts-list">
                        <ui-accordion [allowMultipleExpanded]="true">
                            @for (font of getFonts(); track font.family) {
                                <ui-accordion-item
                                    class="font-item-container"
                                    (toggleChange)="toggleAccordion(font, $event)">
                                    <ng-template ui-accordion-header-template>
                                        <div class="font-item">
                                            <div class="checkbox-container">
                                                <ui-checkbox
                                                    [selected]="
                                                        isFamilySelected(font) ||
                                                        isFamilyIndeterminate(font)
                                                    "
                                                    [indeterminate]="isFamilyIndeterminate(font)"
                                                    (click)="onFamilySelection($event, font)"
                                                    (keypress)="onFamilySelection($event, font)"
                                                    tabindex="0"
                                                    [attr.aria-checked]="
                                                        isFamilySelected(font) ||
                                                        isFamilyIndeterminate(font)
                                                    "
                                                    [disabled]="importing$ | async" />
                                            </div>
                                            <div class="font-details">
                                                <span class="font-name">{{ font.family }}</span>
                                                <span class="font-styles-count"
                                                    >({{ font.styles.length }} styles)</span
                                                >
                                                <div
                                                    class="font-sample font-sample-header load-font"
                                                    [attr.data-family]="font.family"
                                                    [ngClass]="{
                                                        'font-loaded': isFontLoaded(
                                                            fontDefaultStyles[font.family]?.file
                                                        )
                                                    }"
                                                    [ngStyle]="{
                                                        'font-family':
                                                            font.family +
                                                            '-' +
                                                            fontDefaultStyles[font.family]?.name +
                                                            '-' +
                                                            fontDefaultStyles[font.family]?.italic
                                                    }">
                                                    <span
                                                        >The quick brown fox jumps over the lazy
                                                        dog</span
                                                    >
                                                    <span>0123456789</span>
                                                    <span>!?€$£%</span>
                                                </div>
                                            </div>
                                            <div class="actions">
                                                <a
                                                    class="font-link"
                                                    [ngClass]="{
                                                        'nui-font-link': isNewUI()
                                                    }"
                                                    [href]="
                                                        'https://fonts.google.com/specimen/' +
                                                        font.family
                                                    "
                                                    target="_blank"
                                                    (click)="$event.stopPropagation()"
                                                    [uiTooltip]="'Google Fonts link'"
                                                    (aria-label)="
                                                        ('Open ' + font.family + ' in Google Fonts')
                                                    ">
                                                    <ui-svg-icon icon="go-to-url" />
                                                </a>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <div class="font-styles">
                                        @for (style of font.styles; track style.name) {
                                            <div class="font-style-item">
                                                <div class="checkbox-container">
                                                    <ui-checkbox
                                                        [selected]="isStyleSelected(font, style)"
                                                        (click)="toggleStyleSelection(font, style)"
                                                        (keypress)="toggleStyleSelection(font, style)"
                                                        [attr.aria-checked]="
                                                            isStyleSelected(font, style)
                                                        "
                                                        [disabled]="importing$ | async" />
                                                </div>
                                                <div class="font-style-content">
                                                    <div class="font-style-name">{{ style.name }}</div>
                                                    <div
                                                        class="font-sample font-style-sample load-font"
                                                        [ngClass]="{
                                                            'font-loaded': isFontLoaded(style.file)
                                                        }"
                                                        [ngStyle]="{
                                                            'font-family':
                                                                font.family +
                                                                '-' +
                                                                style.name +
                                                                '-' +
                                                                style.italic
                                                        }">
                                                        <span
                                                            >The quick brown fox jumps over the lazy
                                                            dog</span
                                                        >
                                                        <span>0123456789</span>
                                                        <span>!?€$£%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </ui-accordion-item>
                            }
                        </ui-accordion>
                    </div>
                    @if (isLoadingMore) {
                        <div class="loader-container">
                            <ui-loader
                                [inline]="true"
                                class="loader" />
                        </div>
                    }
                }
            }
        </div>
        <selected-fonts class="selected-column" />
    </div>
</div>
