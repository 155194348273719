import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    inject,
    input,
    OnChanges,
    OnInit,
    Renderer2,
    SimpleChange,
    viewChild,
    viewChildren
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UIModule } from '@bannerflow/ui';
import { debounceTime } from 'rxjs';
import { FontService } from 'src/services';
import { FontFamily, FontStyle } from '../../models/fontFamily.model';
import { FontLoaderService } from '../font-style/font-loader.service';

@Component({
    imports: [CommonModule, UIModule],
    selector: 'font-preview',
    templateUrl: './font-preview.component.html',
    styleUrls: ['./font-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FontPreviewComponent implements OnChanges, AfterViewInit, OnInit {
    fontFamily = input.required<FontFamily>();
    fontStyle = input.required<FontStyle>();
    customText = input.required<string>();
    displayType = input.required<string>();

    private renderer = inject(Renderer2);
    private fontLoaderService = inject(FontLoaderService);
    private cdr = inject(ChangeDetectorRef);
    private destroyRef = inject(DestroyRef);
    private fontService = inject(FontService);

    private characters = viewChildren<ElementRef<HTMLDivElement>>('characters');
    private customCharacters = viewChild<ElementRef<HTMLDivElement>>('customCharacters');

    private changesTimeout: number;
    hasLoaded = false;
    fontFamilyName: string;

    ngAfterViewInit(): void {
        this.fontLoaderService.fonts$
            .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100))
            .subscribe((fonts: string[]) => {
                const fontFamily = this.fontFamily();
                const fontStyle = this.fontStyle();
                const name = this.fontService.getFontStyleName(fontFamily, fontStyle);
                if (fonts.includes(name)) {
                    this.hasLoaded = true;
                    this.doChanges();
                    this.cdr.markForCheck();
                }
                this.fontFamilyName = this.getFontFamilyName();
            });
    }

    async ngOnInit(): Promise<void> {
        const fontStyle = this.fontStyle();
        await this.fontLoaderService.loadStyle(this.fontFamily(), fontStyle);
        this.hasLoaded = true;
        this.cdr.markForCheck();
    }

    ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {
        if (this.hasLoaded && (changes['customText'] || changes['displayType'])) {
            this.doChanges();
        }
    }
    convertUnicode(glyph: number): string {
        return String.fromCharCode(glyph);
    }

    private getFontFamilyName(): string {
        return `"${this.fontService.getFontStyleName(this.fontFamily(), this.fontStyle())}", "AdobeBlank"`;
    }

    private doChanges(): void {
        if (this.changesTimeout) {
            clearTimeout(this.changesTimeout);
        }
        this.changesTimeout = window.setTimeout(() => {
            const customText = this.customText();
            if (customText) {
                const customCharacters = this.customCharacters();
                if (!customCharacters) {
                    return;
                }
                this.renderer.setProperty(
                    customCharacters.nativeElement,
                    'innerHTML',
                    this.generateSpans(customText)
                );

                this.replaceMissingGlyphs(customCharacters);
            } else {
                this.setupMissingChars();
            }
        });
    }

    private setupMissingChars(): void {
        this.characters()?.forEach(container => {
            const rawText = container.nativeElement.innerText;

            this.renderer.setProperty(
                container.nativeElement,
                'innerHTML',
                this.generateSpans(rawText)
            );
        });

        this.characters()?.forEach(container => {
            this.replaceMissingGlyphs(container);
        });
    }

    private replaceMissingGlyphs(container: ElementRef<HTMLDivElement>): void {
        for (let i = container.nativeElement.children.length - 1; i >= 0; i--) {
            const child: Partial<HTMLElement> = container.nativeElement.children[i];

            if (!child.offsetWidth) {
                this.renderer.setProperty(
                    child,
                    'outerHTML',
                    `<span style="font-family: AdobeNotDef; color: var(--ui-color-grey-92)">${child.innerText}</span>`
                );
            }
        }
    }

    private generateSpans(rawText: string): string {
        let spannedText = '';

        for (const char of rawText) {
            if (char === ' ') {
                // Ignore spaces
                spannedText += ' ';
                continue;
            }

            spannedText += `<span>${char}</span>`;
        }

        return spannedText;
    }
}
