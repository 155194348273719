import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, OnInit, computed, inject, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    UIConfirmDialogResult,
    UIConfirmDialogService,
    UIDialogDirective,
    UIModule,
    UINewThemeService,
    UINotificationService
} from '@bannerflow/ui';
import { AuthService } from 'src/services/auth.service';
import { SelectionService } from 'src/services/font-selection.service';
import { FontFamily, FontStyle } from '../../models/fontFamily.model';
import { FontService } from '../../services/font-manager.service';
import { FontFamiliesComponent } from '../font-families/font-families.component';
import { FontFamilyComponent } from '../font-family/font-family.component';
import { FontSettingsComponent } from '../font-settings/font-settings.component';
import { FontStylesComponent } from '../font-style/font-styles.component';
import { FontManagerTopbarComponent } from './font-manager-topbar/font-manager-topbar.component';
import { UploadFromDeviceComponent } from './upload-from-device/upload-from-device.component';
import { UploadFromExternalLibraryComponent } from './upload-from-external-library/upload-from-external-library.component';

@Component({
    imports: [
        UIModule,
        FontManagerTopbarComponent,
        FontSettingsComponent,
        FontFamilyComponent,
        FontStylesComponent,
        UploadFromDeviceComponent,
        UploadFromExternalLibraryComponent,
        FontFamiliesComponent
    ],
    selector: 'font-manager',
    templateUrl: './font-manager.component.html',
    styleUrls: ['./font-manager.component.scss', './font-manager.new.component.scss'],
    animations: [
        trigger('openClose', [
            transition(':enter', [
                style({ height: '0px', opacity: 0 }),
                animate('200ms ease', style({ height: '*', opacity: 1 }))
            ]),
            transition(':leave', [animate('200ms ease', style({ height: '0px', opacity: 0 }))])
        ])
    ]
})
export class FontManagerComponent implements OnInit {
    private authService = inject(AuthService);
    private fontService = inject(FontService);
    private selectionService = inject(SelectionService);
    private uiConfirmDialogService = inject(UIConfirmDialogService);
    private uiNotificationService = inject(UINotificationService);
    private uiNewThemeService = inject(UINewThemeService);

    addFontFamilyDialog = viewChild<UIDialogDirective>('addFontFamilyDialog');
    settingsComponent = viewChild<FontSettingsComponent>('settingsComponent');

    selectedFontFamily: FontFamily | undefined;
    newFontFamilyName: string;
    customText: string;
    showSettings: boolean;
    showAllFontFamilies: boolean;

    displayType = 'compact';

    get isLoading(): boolean {
        return this.fontService.isLoading;
    }

    getInstalledOnThisBrandFonts = (): FontFamily[] => this.fontService.getInstalledOnThisBrandFonts();
    getNotOnThisBrandFonts = (): FontFamily[] => this.fontService.getNotOnThisBrandFonts();

    get brandId(): string {
        return this.fontService.brandId;
    }

    isAdmin = this.authService.isAdmin;

    isNewUI = computed(() => this.uiNewThemeService.isNewUI());

    ngOnInit(): void {
        this.setup();

        this.fontService.deletedFontFamily.pipe(takeUntilDestroyed()).subscribe(() => {
            this.showSettings = false;
            this.selectedFontFamily = new FontFamily();
        });

        this.fontService.afterFilesUploaded.pipe(takeUntilDestroyed()).subscribe(fontFamily => {
            this.setup(fontFamily);
        });
    }

    /*
     * Method that runs each time we need to update with the
     * latest fonts. This fetches everything from the backend,
     * and then starts a selection of the proper font family.
     */
    async setup(updatedFamily?: FontFamily): Promise<void> {
        this.selectionService.fontStyles = [];
        if (this.fontService.fontFamilies.length) {
            // What font family should be selected?
            if (updatedFamily || this.selectedFontFamily) {
                const idToSelect = updatedFamily ? updatedFamily.id : this.selectedFontFamily?.id;

                const fontFamily: FontFamily | undefined = this.fontService.fontFamilies.find(
                    ({ id }: FontFamily) => id === idToSelect
                );

                if (fontFamily) {
                    this.selectedFontFamily = fontFamily;
                }
            } else {
                this.selectedFontFamily = this.fontService.fontFamilies[0];
            }

            this.resetSelection(updatedFamily);
            if (this.selectedFontFamily) {
                this.selectFontFamily(this.selectedFontFamily, true);
            } else {
                this.selectedFontFamily = new FontFamily();
            }
        }

        if (updatedFamily) {
            this.selectFontFamily(updatedFamily, false);
        }
    }

    viewAllFontFamilies(): void {
        this.showAllFontFamilies = true;
        this.selectedFontFamily = undefined;
        this.showSettings = false;
    }

    async selectFontFamily(fontFamily: FontFamily, firstSetup: boolean): Promise<void> {
        this.showAllFontFamilies = false;
        this.selectedFontFamily = fontFamily;
        if (!firstSetup) {
            const hasSettingsChanged = !!this.settingsComponent()?.settingsChanged;
            const hasName = this.selectedFontFamily.name !== undefined;
            const newSelectionFamilyHasOtherName = this.selectedFontFamily.name !== fontFamily.name;
            if (hasSettingsChanged && hasName && newSelectionFamilyHasOtherName) {
                const confirmResult: UIConfirmDialogResult = await this.uiConfirmDialogService.confirm({
                    headerText: 'Unsaved changes',
                    text: 'You have unsaved changes on this page.<br>Are you sure you want to change font family?',
                    confirmText: 'Continue without saving'
                });

                if (confirmResult !== 'confirm') {
                    return;
                }
            }
        }
        this.showSettings = false;
        this.selectionService.fontStyles = [];

        this.newFontFamilyName = this.selectedFontFamily.name;
    }

    isOnThisBrand(fontFamily: FontFamily): boolean {
        return !!fontFamily.brandIds.find((brandId: string) => brandId === this.fontService.brandId);
    }

    private getMergeToFamilyDialogText(
        fontFamily: FontFamily,
        mergeToName: string,
        isNameTaken = false
    ): {
        headerText: string;
        text: string;
        confirmText: string;
    } {
        const fontStyleAmount = fontFamily.fontStyles.length;

        return {
            headerText: 'Merge to family',
            text: `${isNameTaken ? 'This name is already taken. Do you want to m' : 'M'}erge ${fontStyleAmount === 1 ? 'this style' : `${fontStyleAmount} styles`} to ${mergeToName}?`,
            confirmText: `Merge font${fontStyleAmount === 1 ? '' : `s (${fontStyleAmount})`}`
        };
    }

    confirmStylesDeletion(): void {
        this.uiConfirmDialogService
            .confirm({
                headerText: 'Are you sure?',
                text: `Do you really want to delete ${this.selectionService.fontStyles.length} font style${this.selectionService.fontStyles.length > 1 ? 's' : ''}?
                <br/><br/>Please note that this will have no effect on existing creatives using this font, but the font will no longer be available in Creative Studio.`,
                confirmText: 'Delete styles'
            })
            .then((answer: string) => {
                if (answer === 'confirm') {
                    this.deleteSelectedFontStyles();
                }
            });
    }

    deleteFontStyle(fontStyle: FontStyle): void {
        // fontStyle has been deleted. lets unselect it
        this.selectionService.fontStyles = this.selectionService.fontStyles.filter(
            ({ id }: FontStyle) => id !== fontStyle.id
        );
    }

    mergeFontFamily(mergeTo: FontFamily, fontFamily?: FontFamily): void {
        const fontFamilyToMerge = fontFamily ?? this.selectedFontFamily;
        if (!!fontFamilyToMerge) {
            this.confirmMergeFontFamily({
                fontFamily: fontFamilyToMerge,
                mergeTo
            });
        }
    }

    async deleteSelectedFontStyles(): Promise<void> {
        if (!this.selectedFontFamily) {
            return;
        }
        await this.fontService.deleteFontStyles(
            this.selectedFontFamily,
            this.selectionService.fontStyles
        );

        // Make sure that the front end shows what has been deleted properly.
        const indexesToDelete: number[] = this.selectionService.fontStyles.map((fontStyle: FontStyle) =>
            this.selectedFontFamily!.fontStyles.findIndex(
                (mainFontStyle: FontStyle) => mainFontStyle.id === fontStyle.id
            )
        );

        // Sort the indexes so that the highest index is first.
        indexesToDelete.sort((a, b) => b - a);

        // Remove all fontstyles starting with the last one on the array.
        indexesToDelete.forEach((index: number) => {
            this.selectedFontFamily!.fontStyles.splice(index, 1);
        });

        this.selectionService.fontStyles = [];

        this.uiNotificationService.open(
            `Font style${indexesToDelete.length > 1 ? 's' : ''} deleted successfully!`,
            {
                type: 'success',
                placement: 'top',
                autoCloseDelay: 3000
            }
        );
    }

    customTextChange(text: string): void {
        this.customText = text;
    }

    changeDisplayType(event: string): void {
        this.customText = '';
        this.displayType = event;
    }

    openSettings(fontFamily?: FontFamily): void {
        if (fontFamily) {
            this.selectFontFamily(fontFamily, false);
        }

        this.showSettings = true;
    }

    toggleSettings(updatedFamily?: FontFamily): void {
        if (!this.selectedFontFamily) {
            return;
        }
        this.resetSelection(updatedFamily);

        this.showSettings = !this.showSettings;

        // If the user press the cancel button
        if (!this.showSettings) {
            this.newFontFamilyName = this.selectedFontFamily.name;
        }
    }

    resetSelection(updatedFamily?: FontFamily): void {
        if (updatedFamily || this.selectedFontFamily) {
            const idToSelect = updatedFamily ? updatedFamily.id : this.selectedFontFamily?.id;

            const fontFamily: FontFamily | undefined = this.fontService.fontFamilies.find(
                ({ id }: FontFamily) => id === idToSelect
            );

            if (fontFamily) {
                this.selectedFontFamily = fontFamily;
            }
        } else {
            this.selectedFontFamily = this.fontService.fontFamilies[0];
        }
    }

    @HostListener('dragover', ['$event'])
    onDragOver(_: Event): void {
        const element: HTMLElement | null = document.getElementById('drag-drop-font');
        if (element) {
            element.className = 'drag-drop-font';
        }
    }

    async confirmMergeFontFamily(event: {
        fontFamily: FontFamily;
        mergeTo: FontFamily;
    }): Promise<void> {
        const answer = await this.uiConfirmDialogService.confirm(
            this.getMergeToFamilyDialogText(event.fontFamily, event.mergeTo.name)
        );

        if (answer === 'confirm') {
            await this.mergeFontFamilies(event.fontFamily, event.mergeTo);
        }
    }

    private async mergeFontFamilies(fontFamily: FontFamily, mergeTo: FontFamily): Promise<void> {
        const newFont: FontFamily | null = await this.fontService.mergeFontFamilies(
            fontFamily.id,
            mergeTo.id
        );
        if (newFont) {
            this.selectFontFamily(newFont, false);
        }
    }
}
