@if (isNewUI()) {
    <ui-tabs
        variant="sm"
        type="secondary">
        @for (option of tabOptions; track option.value) {
            <ui-tab
                [icon]="option.icon"
                [nuiIcon]="option.icon"
                (selectedChange)="selectedTabChange($event, option.value)">
            </ui-tab>
        }
    </ui-tabs>
} @else {
    <div class="button-group">
        <ui-button-group
            [options]="buttonOptions"
            [value]="selectedOption"
            variant="sm"
            (valueChange)="updateSelectedOption($event)">
        </ui-button-group>
    </div>
}
